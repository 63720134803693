
#showMedium-expand{
    flex-wrap: wrap;
    display: inline-flex;
    margin-left: 7%;
    margin-top: 1%;


}
#principal-medium{
    border: 3px solid #f0f0f0;
    width: 215px;
    height: 245px;
    border-radius: 10px;
    margin-top: 5%;
    margin-right: 20px;
}
#principal-medium #linha{
    border-top: 2px solid #C66B00;
    width: 82%;
    text-align: center;
    padding: 0px;
    margin-left: 8%;

}
#conteudo1-medium{
    width: 209px;
    height: 80px;
    margin-top: 3%;
}
#texto{
    margin-top: 3%;
    margin-left: 3%;
    margin-right: 50%;
    margin-bottom: 5%;

}
#conteudo1{
    width: 270px;
    height: 80px;
}
/*#conteudo1-medium #superior h5{
    font-size: 10px;
    color: #C66B00;
    margin-left: 8%;
   
}*/
#nameTop{
    margin-left: 8%;
}
#principal-medium #settingLine{
    padding-right: 15%;
}
#conteudo1-medium #inferior h1{
    font-size: 15px;
    margin-top: 3%;
    margin-right: 7%;
}


#icons{
    margin-left: 10%;
    margin-top: 2%;
    display: flex;

}

#icons p{
    font-size: 8px;
    margin-left: 20%;
}
#icons a{
    padding-right: -50%;
    width: 30px;
}
#icons #icon{
    display: flex;
}
#icons #icon2{
    display: flex;
}


#icons #icon2{
    margin-left: 10%;
    padding:0px
}


#conteudo2-medium{
    margin-left: 3%;
    display: flex;
    position: unset;

    height: 120px;
}

#conteudo2-medium .informacoes{
    border-radius: 5px;
    position: static;
    width: 40%;
    height: 87%;
    padding-bottom: 32%;

    margin-left: 5%;
    border: 2px solid #f0f0f0;
}
#conteudo2-medium .informacoes #oee{
    font-size: 12px;
    text-align: center;
}
#conteudo2-medium .informacoes .segundo{
    margin-top:50%;
    text-align: center;
    font-size: 6px;
}
#conteudo2-medium .informacoes #aprovados{
    font-size: 15px;
    font-weight: bolder;
    padding-bottom: 5%;

}
#conteudo2-medium .informacoes #load{
    display: inline;
    position: fixed;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    margin-left: 1%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}