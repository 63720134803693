#size{
    width: 100%;
    display: flex;
}
#size #size-icon1{
    color: #3270CD;
    margin-right: 10px;
}
#size #size-icon2{
    margin-top: 4px;
    color: #3270CD;
    margin-left: 15px;
}