#menu-index{
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 5;
}

.flex{
  display: flex;
}

.flex button{
  margin-left: 20%;
  border: none;
  padding: 5px; 
  color: white;
  background-color: #052554;
}

.flex button:hover{
  color: #888A8C;
}

nav{
    background-color: #052554;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 0.7%;
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: space-between; /*faz com que os elementos filhos possam ser alinhados em lados opostos*/
    z-index: 2;
    
}


#dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    margin-left: -90%;
    margin-top: 130%;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding-bottom: 100%;
    padding-right: 330%;
    
  }

  /* Links inside the dropdown */
#dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    padding-top: 30px;
    margin-top: 10px;
    text-align: center;
    margin-left: 20px;
  }
  
  /* Add a grey background color to dropdown links on hover */
  #dropdown-content a:hover {
    /*background-color: #ddd;*/
    
  }
 

  #dropdown-content a p{
    float: left;
    color: #878A8C;
  }
  
  #dropdown-content a #icon{
    color: #3270CD;
  }
  /* Show the dropdown menu on hover */
  /*.dropdown:hover .dropdown-content {
    display: block;

  }
*/