#top{
    display: flex;
    margin-left: 6.64%;
    margin-right: 6.64%;
    margin-top: -1%;
}

#top #top-1{
    width: 56.8%;
    margin-top: 3%;
    margin-right: 1.95%;
    align-items: center;
    align-self: center;
    align-content: center;
}

#top #top-2{
    width: 14.5%;
    padding-top: 1%;
    margin-top: 2%;
    margin-right: 1.95%;
    margin-left: 5%; /*Talvez temporario*/
    align-items: center;
    align-self: center;
    align-content: center;
}

#top #top-3{
    width: 11.5%;
    margin-top: 2.5%;
    margin-left: 5%; /*Talvez temporario*/
}