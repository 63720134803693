

.col-md-5.alinhamento{
    display: flex;
    
}
.col-md-7{
    margin-bottom: 15%;
}

.col-md-5.data{
    margin-left: 15%;
}

.col-md-5.data p{
    margin-left: -4%;
    font-size: 13px;
}

.col-md-9 .border{
    width: 80%;
}

#cont1{
    display: flex;
    margin-left: 16%;
    width: 80%;
}

