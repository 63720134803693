#bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
#bar input{
    height: 30px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    padding-left: 5%;
}
#bar #bar-icon{
    position: absolute;
    margin-left: 1%;
    width: 50%; /*controla tamanho da barra, principalmente em relação ao componente Top*/
}
#bar #bar-icon #bar-icon1{
    color:#3270CD ;
    margin-left: 2%;
    position: absolute;
    margin-top: 0.5%;
}
#bar #bar-icon #bar-icon2{
    color:#3270CD ;
    left: 95%;
    position: absolute;
    margin-top: .5%;
}

/*.box {
    text-align: left;
    border: black ridge 1px;
    border-radius: 10px;
    margin: 3px;
    width: 20rem;
    padding-left: 10px;
}*/