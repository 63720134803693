#drop {
    position: fixed;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding-top: 5%;
    width: 15%;
    height: 100vh;
    background-color: white;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
   

}

#drop a {
    display: flex;
    margin-left: 10%;
    margin-top: 8%;
    text-decoration: none;
    color: #3270CD;
    cursor: pointer;
}

#drop a #icon {
    padding-right: 4%;


}