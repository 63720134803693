#showLarger-expand{
    margin-left: 7%;
    margin-top: 1%;
    display: inline-flex;
    flex-wrap: wrap; /*Calcula as dimensões máximas e ajusta os elementos conforme necessário*/

    padding-bottom: 2%;
}

#principal{
    border: 3px solid #f0f0f0;
    width: 312px;
    height: 245px;
    border-radius: 10px;
    margin-top: 5%;
    margin-right: 20px;
}
/*#principal #linha{
    border-top: 2px solid #C66B00;
    width: 98%;
    text-align: center;
    padding: 0px;
    margin-top: -4%;
    margin-left: 7%;
}*/

#conteudo1 #superior-1 h5{
  
}
#conteudo1{
    width: 270px;
    height: 80px;
}

#conteudo1 #texto h1{
    font-size: 16px;
    margin-top: 3%;
    display: flex;
    
    
}
.row{
    height: 20px;
}
#conteudo1 #superior, #inferior{
    margin-top: 3%;
    margin-left: 8%;
    margin-right: 50%;
    margin-bottom: 5%;
    white-space: pre-wrap; /*Se não colocar essa propriedade, o JS não vai ler o \n da função como uma quebra de linha*/
    width: 100%;
}

#conteudo1 #inferior h1{
    font-size: 16px;
    margin-top: 3%;
    display: flex;
    height: 38px;
}
/*#conteudo1 #texto h5{
    font-size: 10px;
    color: #C66B00;
}*/

#icons{
    margin-left: 10%;
    margin-top: 2%;
    display: flex;

}

#icons p{
    font-size: 8px;
    margin-left: 20%;
}
#icons a{
    padding-right: -50%;
    width: 30px;
}
#icons #icon{
    display: flex;
}
#icons #icon2{
    display: flex;
}


#icons #icon2{
    margin-left: 10%;
    padding:0px
}


#conteudo2{
    display: flex;
    position: unset;
    padding-top: 1px;
    height: 50px;
}

#conteudo2 .informacoes{
    border-radius: 5px;
    position: static;
    width: 30%;
    height: 10%;
    padding-bottom: 20%;
    margin-top: 2%;
    margin-left: 2%;
    border: 2px solid #f0f0f0;
    font-size: 0.5rem;
    text-align: center;
    font-weight: 400;
}
#conteudo2 .informacoes b{
    margin-top: 5%;
    text-align: center;
    font-size: 1rem;

    display: grid;
}
#conteudo2 .informacoes #oee{
    font-size: 11px;
    text-align: center;
}
#conteudo2 .informacoes .segundo{
    margin-top:50%;
    text-align: center;
    font-size: 6px;
}
#conteudo2 .informacoes #aprovados{
    font-size: 15px;
    font-weight: bolder;
    padding-bottom: 5%;

}
#conteudo2 .informacoes #load{
    display: inline;
    position: fixed;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    margin-left: 1%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}


#graphicBar{
    margin-top: 9%;
    width: 100%;
    height: 18%;
}
#graphicBar #firstDivGraphicBar{
    height: 40%;
    background-color: #E5E5E5;
    margin-left: 5%;
    margin-right: 5%;
}

#graphicBar #firstDivGraphicBar #percent{
    color: #0C4394;
    font-size: 0.9rem;
    font-weight: bolder;
    text-align: right;
}

/* #graphicBar #secondDivGraphicBar{
    position: relative;
    background-color: #0C4394;
    margin-top: 9%;
    width: 50%;
    max-width: 100%;
    height: 100%;
} */