#showSmall-expand{
    flex-wrap: wrap;
    display: inline-flex;
    margin-left: 7%;
    margin-top: 1%;
}

#principal-small{
    border: 3px solid #f0f0f0;
    width: 98px;
    height: 147px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 20px;
}
#principal-small #settingLine{
    padding-right: 10%;
}
#principal-small #linha{
    border-top: 2px solid #C66B00;
    width: 90%;
    text-align: center;
    padding: 0px;
    margin-left: 5%;

}
#conteudo1-small #texto{
    margin-top: 3%;
    margin-left: 3%;
    margin-right: 50%;
    margin-bottom: 5%;
    font-size: 15px;
    width: 90px;
    height: 39px;

}
#conteudo1-small #texto h1{
    font-size: 10px;
    margin-top: 3%;

}
#conteudo1-small #texto h5{
    font-size: 10px;
    color: #C66B00;
}

#icons-small{
    margin-left: 10%;
    margin-top: -10%;
    display: flex;

}

#icons-small p{
    font-size: 8px;
    margin-left: 20%;
}
#icons-small a{
    padding-right: -50%;
    width: 30px;
}
#icons-small #icon{
    display: flex;
}
#icons-small #icon2{
    display: flex;
}


#icons-small #icon2{
    margin-left: 10%;
    padding:0px
}

#conteudo2-small{
    margin-top: -25px;
    margin-left: 3%;
    display: inline-block;
    position: static;
    padding-top: 1px;
    font-size: 10px;
    text-align: center;
    height: 90px;
}

#conteudo2-small #oee{
    margin-top: -11px;
}

/*
#conteudo2-small .informacoes{
    border-radius: 5px;
    position: static;
    width: 40%;
    height: 87%;
    padding-bottom: 32%;
    margin-top: 3%;
    margin-left: 5%;
    border: 2px solid #f0f0f0;
}
#conteudo2-small .informacoes #oee{
    font-size: 12px;
    text-align: center;
}
#conteudo2-small .informacoes .segundo{
    margin-top:50%;
    text-align: center;
    font-size: 6px;
}
#conteudo2-small .informacoes #aprovados{
    font-size: 15px;
    font-weight: bolder;
    padding-bottom: 5%;

}
#conteudo2-small .informacoes #load{
    display: inline;
    position: fixed;
    border: 16px solid #f3f3f3; 
    border-top: 16px solid #3498db;
    border-radius: 50%;
    margin-left: 1%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}*/