.principal-workstationlist{
    padding-top: 3%;
}
#ws-content{
    width: 95%;
    margin: 10px auto
}

#ws-table-description {
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-bottom: 2px solid black;
}

#ws-table-description p{
    width: 150px;
}

#ws-table-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 2px solid #e5e5e5;
    font-size: 12px;
    text-align: center;
}

#ws-table-data p{
    width: 150px;
}